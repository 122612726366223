.global-error-wrap {
  max-width: 600px;
  font-size: 16px;
  text-align: center;
  color: var(--blue);
  font-weight: 500;
}

.main-error-div {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
