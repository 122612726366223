/* .form-control:focus {
    box-shadow: none;
    border-color: #55374a
} */

.form-select:focus {
    box-shadow: none;
    border-color: #55374a
}

.profile-button {
    background: #55374a;
    box-shadow: none;
    border: none
}

.profile-button:hover {
    background: #55374a
}

.profile-button:focus {
    background: #55374a;
    box-shadow: none
}

.profile-button:active {
    background: #55374a;
    box-shadow: none
}

.back:hover {
    color: #55374a;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.add-experience:hover {
    background: #55374a;
    color: #fff;
    cursor: pointer;
    border: solid 1px #55374a;
}



.upload {
    color: #55374a;
    background-color: rgba(0, 0, 0, 0.397);
    margin-top: -50px;
    width: 150px;

}

.up {
    color: #55374a;
    cursor: pointer;
}

.btnwugfiwgf {
    background-color: #55374a;
    color: #fff;
}

.pro-outlinebutton {
    border-color: #55374a;
    color: #55374a;
    background-color: transparent;
}

.pro-darkbutton {
    background-color: #55374a;
    color: white;
}

.trysizeplace input::placeholder {
    font-size: 12px;
}