.form-select:focus {
    box-shadow: none;
    border-color: #55374a
}

.profile-button {
    background: #55374a;
    box-shadow: none;
    border: none
}

.profile-button:hover {
    background: #55374a
}

.profile-button:focus {
    background: #55374a;
    box-shadow: none
}

.profile-button:active {
    background: #55374a;
    box-shadow: none
}

.back:hover {
    color: #55374a;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.add-experience:hover {
    background: #55374a;
    color: #fff;
    cursor: pointer;
    border: solid 1px #55374a
}



.upload {
    color: #55374a;
    background-color: rgba(0, 0, 0, 0.397);
    margin-top: -50px;
    width: 150px;

}

.up {
    color: #55374a;
    cursor: pointer;
}

.btnwugfiwgf {
    background-color: #55374a;
    color: #fff;
}

.eoifhpiwehfoihweoifiohweofiohaeifhiqfbaioehdnuqoihn {
    color: red;
}

.pro-outlinebutton {
    border-color: #55374a;
    color: #55374a;
    background-color: transparent;
}

.pro-darkbutton {
    background-color: #55374a;
    color: white;
}


.form-control.is-valid,
.was-validated .form-control:valid {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border-color: #ced4da !important;
    border: 1px solid #ced4da !important;
    background-image: none !important;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border-color: #ced4da !important;
    border: 1px solid #ced4da !important;
    background-image: none !important;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #55374a !important;
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #55374a !important;
}

.Mui-error {
    border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
}

/* .form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e), url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);


} */

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 5l6 6 6-6"/></svg>') !important;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #212529 !important;
}


#idtestingprofilepicvalidation input[type="file"] {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    z-index: -1;
}

#idtestingprofilepicvalidation label {
    display: inline-block;
    width: 150px;
    background-color: rgba(0, 0, 0, 0.100);
    color: var(--blue);
    cursor: pointer;
}