.cardBox {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
  
    grid-gap: 30px;
    margin-top: 50px;
}

.wgfiuwrgiu{
   
    text-decoration: none;
    color: var(--blue);
}




.slhishi{
    padding-top: 25vh;
}

.padunnor{
    font-size: 2rem;
}





 .carrrr{
     margin-top: 50px;
    position: relative;
    background: white;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 7px 25px rgba(0,0,0,0.4);
    color: var(--blue);
    
}




.numbers {
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--blue);
}


@media screen and (max-width: 1558px) {
    
    .carrrr {
        height: 300px;
    }
}

@media screen and (max-width: 1010px) {
    
    .carrrr {
        height: 350px;
    }
}

@media screen and (max-width: 803px) {
    
    .carrrr {
        height: 400px;
    }
}

@media screen and (max-width: 715px) {
    
    .carrrr {
        height: 450px;
    }
}

@media screen and (max-width: 700px) {
    
    .carrrr {
        height: 300px;
    }
}

@media screen and (max-width: 585px) {
    
    .carrrr {
        height: 400px;
    }
}
@media screen and (max-width: 767px) and (min-width: 576px){
.slhishi{
    padding-top: 10vh;
}
}

@media screen and (max-width: 430px) {
    .slhishi{
        padding-top: 10vh;
    }
    }

