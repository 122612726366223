.sdlgindsiobgiozsd{
    padding-top: 150px;
}


.djgaeihfenknf{
    
    border: 3px solid #55374a;
    width: 50%;
    align-items: center;
    border-radius: 20px;
    
}

.textboxdrete{
   
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      
}

.eufgiueg{
    color: #55374a;
    
}

.wygdiwgdigwidg{
    color: white;
    background-color: #55374a;
}

.btn-udaguadgouao{
    color: white;
    background-color: #55374a;
    border-radius: 30px;
}
.btn:hover{
    color: white;
}