@media screen and (min-width: 768px) and (max-width: 1173px) {

    .btn-search-pro {
        font-size: 11px !important;
        height: 35px;
        /* padding: ; */
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .css-1jqq78o-placeholder{
        font-size: 12px;
        }
        
}

.btn-search-pro {
    text-transform: none !important;
    border-color: #a0a3a3 !important;
    color:#a0a3a3 !important;
}
.css-1nxu04f-control{
    border-color: #a0a3a3 !important;
}
@media screen and (min-width: 768px) {
    .pro-col-s {
        padding-right: 0px !important;
    }

    .pro-col-e {
        padding-left: 0px !important;
    }

}


@media screen and (max-width: 768px) {
    .btn-search-pro {
        font-size: 12px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

}



