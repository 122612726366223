.btnskip {
    background-color: var(--blue);
    color: white;
}
.btnskip:hover{
    border: 1px solid var(--blue);
    background-color: white;
    color: var(--blue);
}
.btnskip:focus {
    outline: none;
    box-shadow: none;
}

.alert-info-pros{
    background-color: white;
}

.rioughourh{
 
    background-color: white;
}


  