.specscarosel23876487236> .carousel-control-next .carousel-control-prev {
    border: 1px solid black;
    background-color: black;
    top: 170px !important;
    bottom: unset !important;
    width: fit-content !important;
}

.bytheBy {
    right: 98px;
    margin-left: 50px;
    margin-top: -97px;
    padding: 3px;
    height: 34px;
    width: 34px;
    border-radius: 15px;
    position: absolute;
}

