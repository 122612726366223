@media only screen and (min-width: 1200px) {
    .cont{
        max-width: 800px;
    }
    }
    .cont{
        width: 100%;
    }

    .spBtn{
        border: none;
        background-color: rgba(127, 255, 212, 0);
  
    }

    .boXe{
        width: min-content;
        height: 24px;
    }
  