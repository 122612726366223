.thumbnailpost {
    height: 50px;
    width: 50px;
    border-radius: 35px;
    padding: 0px;

}
.yhji5j{
    border-radius: 10px;
}

.snblsnlbns{
    height: 40vh;
    width: 100%;
    object-fit: contain;
    
}



.ekgdfukweguobwljbvuwgcuonewgg{
    margin-top: 170px;
    height: 20px;
}

@media screen and (max-width:720px) {
    .ekgdfukweguobwljbvuwgcuonewgg{
        margin-top: 140px;
        
     
    }
}

@media screen and (max-width:630px) {
    .ekgdfukweguobwljbvuwgcuonewgg{
        margin-top: 120px;
        
     
    }
}

@media screen and (max-width:590px) {
    .ekgdfukweguobwljbvuwgcuonewgg{
        margin-top: 150px;
        
     
    }
}
@media screen and (max-width:470px) {
    .ekgdfukweguobwljbvuwgcuonewgg{
        margin-top: 120px;
        
     
    }
}
@media screen and (max-width:390px) {
    .ekgdfukweguobwljbvuwgcuonewgg{
        margin-top: 90px;
        
     
    }
}

.dknlkenven{
    padding-top: 100px;
    padding-right: 30px;
}

.wiuofgwudgiuswgufigwiugvuig{
    font-size: 13px;
}

.social-media-button-pros{
    height: 50px;
    width: 50px;
}





/* .fswgrh::-webkit-input-placeholder {
    padding-top: 10px;
 }

 .fswgrh{
    text-indent:-20px;
 } */


/* .sabbbbbbbbb{
   
    margin-right: -30px;
    margin-top: -60px;
} */
.rwkuviueriuvgirugv{
    margin-right: -85%;
}

.post {
    display: block;
    /* width: min-content; */
    border-radius: 8px;
    background-color: rgb(252, 250, 249);
    padding: 10px 15px 10px 15px;
    overflow: hidden;
}

.headerinfo {
    /* display: flexbox; */
    height: 60px;
    width: 100%;

}

.headerContent img {
    background-size: fill;
    margin: auto;
}

.headerContent {
    width: inherit;
    font-size: 17px;
    line-height: 24px;
    /* text-align: justify;
    text-justify: inter-word; */

}
.dlvjslfjqepfjw{
    cursor: pointer;
}

.postinfo h6  p {
    margin-bottom: 0px;
}

.threedot {
    height: 30px;
    cursor: pointer;
    /* margin-left: 90%; */
    width: fit-content;
    display: table-cell;
    vertical-align: middle;
}


.dotoptionbtn:hover {
    background-color: rgb(190, 185, 185);
    border-radius: 80px;

    /* padding: 5px; */
}

.dotoptionbtn:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
}

.dotoptionbtn {
    display: table;
    width: 50px;


}

/* .headerContent{
    height: 30px;
} */

.postContent {
    position: relative;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
   
    width: 100%;
    background-color: white;

 
}

.img-fluide {
    object-fit: cover;
    width: 100%;
}

.like, .comment, .share {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    height: 50px;

}
.liked{
    border: #661177;
}
.postTrigger{
    height: 40px;
}

.postTrigger button {
    line-height: 14px;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    border: none;
}

.postTrigger button:hover {

    background-color: rgb(221, 221, 221);
    width: 100%;
    height: 100%;
    border: none;
}

.arrow{
    color: rgb(248, 202, 48);
}

.liked {
    background-color: rgb(221, 221, 221);
}




.ekfheihfuewgfioue{
    background-color: transparent;
    color: black;
    border: none;
}

.commentSection {
    /* animation: 3s linear .1s colorIt; */
    /* height: 50px; */
    /* display: inline; */
}

.selfthumbnailcomment {
    height: 50px;
    width: 50px;
    border-radius: 35px;
    padding: 0px;

}

.selfthumbnailcommentt {
    height: 40px;
    width: 40px;
    border-radius: 35px;
    padding: 0px;

}

.commentInput {
    width: 80%;
    border-radius: 10px;
    padding-left: 15px;
    /* min-height: 790px; */
    padding-right: 15px;
    border-color: rgb(207, 204, 204);
    box-shadow: none;
    border-style: solid;
resize: none;
}

.fullhr {
    width: 100%;
    z-index: 1000;
}

.commentInput:focus {
    outline: none;
    /* background-color: rgb(250, 250, 241); */

    /* border-radius: 30px; */
    padding-left: 15px;
    box-shadow: none;
    border-style: solid;
}

.Commentcontainer {
    background-color: whitesmoke;
    padding-left: 5px;
    border-radius: 5px;
}

.cot {
    width: min-content;
}

.last {
    width: 100%;
}

.tab {
    display: table;
}
.viewHide{
    font-size: 15px;
}

.cell {
    width: fit-content;
    display: table-cell;
    vertical-align: middle;
}

.celle {
    width: fit-content;
    display: table-cell;

}

.pThumbcontainer {
    width: 66px;
    overflow: hidden;
}

.commentSent {
    /* margin-left: -60px; */

}

.FaPaperPlane {
    color: violet;
    height: 30px;
    width: 30px;
    z-index: 20;
}

.FaPaperPlane:hover {
    color: rgb(199, 57, 199);
}

.FaPaperPlane :active {
    color: rgb(57, 199, 140);
}

.commentTopbar {
    height: 22px;
}

.pThumbcontainerr {
    width: 50px;
}

.replybtn {
    /* text-align: end; */
    font-size: 14px;
    font-weight: 700;
}
.referenceat{
    text-decoration: none;
}
.grid-item1 {
    grid-area: header;
    width: 100%;
}

.grid-item2 {
    grid-area: menu;
    text-align: center;
}

.grid-container {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-areas:
        'header header header header header header header header header header header header header header header menu'
}
.commentauthors{
    font-size: 12px;
    font-weight: 700;
}
.commentauthorsdesig{
    font-weight: 500;
    font-size: 10px;
}
.commentbody {
    font-size: 14px;
    line-height: 16px;
}
@media only screen and (max-width:576px) {
    .postinfobottom{
        font-size: 13px;
        font-weight: 500;
    }
    .threedot {
        height: 20px;
        cursor: pointer;
        /* margin-left: 90%; */
        width: fit-content;
        display: table-cell;
        vertical-align: middle;
    }
    .commentInput {
        /* min-height: 50px; */
        margin-top: 0px;
        margin-bottom: 0px;
        /* height: 81px; */
        padding-top: 5px;
        line-height: 16px;
        font-size: 14px;
    }

    .commentInput:active {}

    .pThumbcontainerr {
        margin-top: -100px;
        width: 34px;
    }

    .commentSent {

        margin-top: -70px;
        /* margin-left: -50px; */
    }

    .like, .comment, .share {
        /* display: flex; */
        align-items: center;
        justify-content: center;
        height: 40px;
        font-size: 10px;

    }

.hrline{
    color:#661177;
}
    .postTrigger button {
        font-size: 12px;
        background-color: rgba(255, 255, 255, 0);
        width: 100%;
        height: 100%;
        border: none;
    }

    .thumbnailpost {
        height: 50px;
        width: 50px;
        border-radius: 35px;
        padding: 0px;

    }

    .commentbody {
        font-size: 13px;
        line-height: 14px;
    }

    .post {
        display: block;
        /* width: min-content; */
        border-radius: 8px;
        background-color: rgb(252, 250, 249);
        padding: 5px;
        overflow: hidden;
    }

    .selfthumbnailcomment {
        width: 30px;
        height: 30px;
        
    }
}

.dropdown-item:hover{
    color: white;
    background-color: var(--blue);
}


.afhouweh {
    background-color: white;
    text-align: center;
    height: 30%;
    padding: 10px;
}

.carItemmm {
    width: 100%;
    max-height: 340px;
    height: fit-content;
    object-fit: contain;
}

@media screen and (max-width:900px) and (min-width:500px) {
    .postTrigger button{
        font-size: 15px;
    }
    .comment{
        height: 40px;
    }
}



