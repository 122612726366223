.rnd {
    border-radius: 55px;
    background-color: var(--blue);
}

.rnd:disabled {
    border-radius: 55px;
    color: var(--blue);
    background-color: white;
    border-color: var(--blue);
}

.cont {
    background-color: rgb(255, 255, 255);
}

@media only screen and (min-width: 506px) {
    .cont {
        width: 500px;
    }
}

.Sp {
    width: 200px;
}

@media only screen and (max-width: 320px) {

    .Tk {
        font-size: 12px;
    }

}

#myProgress {
    width: 100%;
    background-color: #ddd;
    border-radius: 50px;
    overflow: hidden;

}

#myBar {
    width: 0%;
    height: 4px;
    background-color: var(--blue);
    transition: width 0.5s;
    border-radius: 50px;

}

.spBtn {
    border: none;
    background-color: rgba(127, 255, 212, 0);

}

.boXe {
    width: min-content;
    height: 24px;
}

.disposeBox {
    padding: 5px 5px 5px 5px;
    width: fit-content;
    display: inline-block;
    
}

.addBtn{
    background-color: rgba(127, 255, 212, 0);
    border: none;
    outline: none;
    margin-left: -50px;
}
.addct{
    margin-right: -50px;
}