.chatspace {
    height: calc(100vh - 225px); 
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex; 
     flex-direction: column-reverse;
     border-radius: 5px;
    
}

.sds {
    margin-left: 65px;
}


.spsp {
    display: inline;
}

.verticalStu {
    height: 100vh;
}

.chatList {
    height: 100%;
    overflow-y: scroll;
    border-radius: 5px;


}

.chatconsole {
    height: 75px;
    bottom: 0;
}

.f {
    height: 75px;
    border-radius: 5px;
}

.s {
    height: 88%;
}
.iopoioipoipoipoipoi{
    border: 1px solid var(--blue);
    background-color: white;
    color: var(--blue);
}
.iopoioipoipoipoipoi:hover{
    /* border: 1px solid var(--blue); */
    background-color: var(--blue);
    color: white;
}
.mainCon {
    /* height: 90vh; */
    overflow: hidden;
    position: fixed;
    padding-top: 50px;
}
.dkhs{
    background-color: var(--blue);
    border-radius: 5px;

}

.chatTabpro{
    background: rgb(236, 233, 233);
    border-radius: 5px;
}
#test{
    position: fixed;
    bottom:calc(100vh - 180px);
}
.message {
    word-break: break-all;
    overflow-wrap: break-word;
    border-radius: 15px;
    width: fit-content;
    padding: 5px;
}
.htyyth{
    word-break: break-all;
}
.mesbox {
    max-width: 85%;
}

.chatAvatar {
    display: inline-block;
}

.rightc {
    position: relative;
    right: 0;
}
.encMess{
    border-radius: 15px;
}

.ENQUIRIESENQUIRIES{
    font-weight: 400;
}

.welcometocub{
    font-size: 20px;
}
@media only screen and (max-width: 576px){
.mainCon{
    padding-top: 43px;
}

.chatspace {
    height: calc(100vh - 235px); 
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex; 
     flex-direction: column-reverse;
    
}
.verticalStu{
    padding-left: 2px;
    padding-right: 02px;
}
.risp{
    padding-right: 5px;
}

.inviteName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    max-width: 200px;
  }

  .groundName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 200px; */
    max-width: 260px;
  }
.s{
    background-color: white;
}
}

.WECOMECUB{
   height: 400px;
}
.cubmesss{
    font-size: 30px;
    color:  rgb(113, 119, 113);
}
.viewmemberHeading{
    font-weight: 400;
}
/*  */

.pro-spaces-button4{
    background-color: var(--blue);
    border: white;
    color: white;
}
