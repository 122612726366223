/* @font-face {
    font-family: MAIAN;
    src: url(MAIAN.TTF);
  }
  
  body {
    font-family:MAIAN ;
    margin-top: 20px;
    overflow-y: scroll;
    height: 100vh;
  } */


.serrow {
    margin-left: 290px;
    
}

@media screen and (max-width:1300px) {
    .serrow {
        margin-left: 170px;
    }

 }

 @media screen and (max-width: 576px) {
     .serrow{
         margin-left: 0px;
     }
     .btn4 {

     }
 }

 .MuiModal-root{
    z-index: 999999999 !important;
 }

