.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1300;
    top: 0;
    left: 0;
    background: var(--blue);
    overflow-x: hidden;
    overflow-y: scroll;
    transition: 0.5s;
    padding-top: 70px;
   
    color: white;
  }
  .eihfiwfhih{
    margin-top: -10px;
  }
  
  .sidenav a {
    padding: 8px 15px 15px 15px;
    text-decoration: none;
    font-size: 15px;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    width: 240px;
  }

  .sidenavicon{
      padding-right: 10px;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    background: transparent;
    color: white;
    border: none;
  }


.closeinto {
  font-size: 25px;
}


.kefvniebvi{
  text-decoration: none;
  color: black;
}

.bignav
{
    
    height: 70px;
    border-bottom: 5px solid  var(--blue);
    /* box-shadow: 0 7px 25px rgba(0,0,0,0.4); */
}

.sidenavicon {
    font-size: 30px;
}

.ksjdbgvkjaviubeiwuvcmewiobv{
  color: red;
  font-size: 10px;
  margin-left:-19px ;
}

.ksjdbgvkjaviubeiwuvcmewiobvdddf{
  color: red;
  font-size: 10px;

  margin-left:-15px ;
}

#button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    margin-left: -50px;
   
}

.newnav {
    /* display: none; */
    background: var(--blue);
   
    width:100%;
    
    
}

.floattog {
  color: white;
  border: none;
  background: var(--blue);
}

.sad {
  color: #ffffff;
}

.sad:hover {
  color: #ffffff;
}

.poooooperkechu{
  position: fixed;
  z-index: 9999999999;
  height: 100vh;
  right: 0;
  width: calc(100% - 250px);
}


  


.img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: -5px;
}

.dfg :active {
    color: var(--blue);
    
    border-radius: 50%;
}


.hai {
    display:  grid;
    place-items: center;
}




.drop {
  z-index: 10001;
}






 @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
    
  }
@media screen and (max-width: 576px){
    #navbar-brand {
        display: none;
    }
    .logo {
        display: block;
        float: left;
    }
    .not {
      width: 100%;
    }
    .bg {
        background-color: var(--blue);
        z-index: 1300;
    }
    .navContent {
      display: none;
    }
    .bignav {
      display: none;
    }

    .newnav {
       
        position: fixed;
        /* margin-left: -12px; */
        z-index: 10000;
        top: 0px;
    }
  
   
}

.pronewnav{
 position: relative;
 z-index: 1500;
}



@media screen and (min-width: 576px){
  #notification {
    display: none;
  }
}


.poprjpgvijspjva{
  margin-top: -30px;
}


@media screen and (min-width:540px) {

  .newNavButton {
    padding-top: 20px !important;
    padding-right: 20px !important;
  }
  
}