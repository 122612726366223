/* Hide scrollbar for Chrome, Safari and Opera */
:root {
  --blue: var(--blue);
  --white: #ffffff;
  /* --gray:#f5f5f5;
  --black1:#222;
  --black2:#999; */
  --display: var(--display);
}

@font-face {
  font-family: MAIAN;
  src: url(MAIAN.TTF);
}

.jrgfrjflobjgt {
  font-family: MAIAN;
}
.maiandra-gd-font {
  font-family: MAIAN;
}

.img-fluid-for-post {
  width: 100% !important;
  height: auto;
}

@media screen and (min-width:1300px) {


  /* .iconlobbytextfont {
    font-size: 15px !important;

  } */
}


.currentborder1 {
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.currentborder2 {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.iconsizelobbylg {
  margin-top: -3px;
}

.st-btn {
  display: inline-block !important;
}

.iconlobbytextfont {
  font-size: 15px !important;
  /* color: red; */

}


.pro-spaces-button {
  text-transform: uppercase;
}

.pro-spaces-button3 {
  background-color: var(--blue) !important;
  color: white !important;
}

.forntweightreducer {
  font-weight: 400 !important;
}

.pro-spaces-button-semi {
  border-color: var(--blue) !important;
  background-color: transparent !important;
  color: var(--blue) !important;
}

.pro-spaces-button-semi:hover {
  border-color: var(--blue) !important;
  background-color: transparent !important;
  color: var(--blue) !important;
}

.lobbySearchinfo {
  display: block;
  width: min-content;
  margin-top: -33px;

}

*::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* @media screen and (max-width: 580px) {
  *::-webkit-scrollbar {
    display: block;
   
  }
  .sabscrollbar{
    margin-top: 70px;
    height:calc(100vh - 70px) !important;
    overflow-y: scroll !important;
    }
    .sabscrollbar::-webkit-scrollbar {
      width: 10px !important;
      margin-top: 30px !important;
    }
    

    .sabscrollbar::-webkit-scrollbar-track {
      box-shadow: transparent !important; 
      border-radius: 5px !important;
    }
     
    
    .sabscrollbar::-webkit-scrollbar-thumb {
      background: var(--blue) !important; 
      border-radius: 5px !important;
    }
    
    
    .sabscrollbar::-webkit-scrollbar-thumb:hover {
        background: var(--blue) !important; 
    }
    .sabscrollbar::-webkit-scrollbar-button {
      height: 20px;
    }
    .sabdummyheightcss{
      margin-top: 0px !important;
    }
  } */

.valid {
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 99998;
}

.apptheamcolor {
  color: var(--blue);
}

.testtools {
  position: absolute;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.481);
  height: 100vh;
  width: 400px;
}

#floatbutton {
  position: absolute;
  top: 50%;
  z-index: 99999;
}


.cls-1 {
  font-size: 120px;
}

.cls-1,
.cls-2 {
  fill: var(--blue);
  font-family: "Maiandra GD";
}

.cls-2 {
  font-size: 48px;
}

.cls-5 {
  font-size: 120px;
}

.cls-5,
.cls-6 {
  fill: #ffffff;
  font-family: "Maiandra GD";
}

.cls-6 {
  font-size: 48px;
}


.cls-8 {
  font-size: 100px;
}

.cls-8,
.cls-9 {

  fill: #ffffff;
  font-family: "Maiandra GD";
}

.cls-9 {
  font-size: 40px;
}



.cls-11 {
  font-size: 50px;
}

.cls-11,
.cls-12 {

  fill: #ffffff;
  font-family: "Maiandra GD";
}

.cls-12 {
  font-size: 20px;
}



/* {
<--------------------footer svg---------------------->
} */






.cls-15 {
  font-size: 72px;
}

.cls-15,
.cls-25,
.cls-35,
.cls-45,
.cls-55,
.cls-65,
.cls-75 {
  fill: #d3daca;
  text-anchor: middle;
  font-family: "Maiandra GD";
}

.cls-25 {
  font-size: 48px;
}

.cls-35 {
  font-size: 24px;
}

.cls-45 {
  font-size: 48.074px;
}

.cls-55 {
  font-size: 30px;
}

.cls-65 {
  font-size: 49.139px;
}

.cls-75 {
  font-size: 56.989px;
}

.cls-a1 {
  font-size: 72px;
}

.cls-a1,
.cls-a2,
.cls-a3,
.cls-a6 {
  fill: #d3daca;
}

.cls-a1,
.cls-a2,
.cls-a3,
.cls-a4,
.cls-a5,
.cls-a6 {
  text-anchor: middle;
}

.cls-a1,
.cls-a3 {
  font-family: "Maiandra GD";
}

.cls-a2 {
  font-size: 48px;
}

.cls-a2,
.cls-a5,
.cls-a6 {
  font-family: "Microsoft Sans Serif";
}

.cls-a3 {
  font-size: 24px;
}

.cls-a4 {
  font-size: 48.074px;
  fill: #8be913;
  font-family: "Monotype Corsiva";
}

.cls-a5 {
  font-size: 30px;
  fill: #8beb11;
}

.cls-a6 {
  font-size: 39.311px;
}


/* @media only screen and (pointer: coarse) and (min-width: 320px) and (max-width: 700px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

#Tommy {
  display: none;
}

@media only screen and (pointer: coarse) and (min-width: 320px) and (max-width: 1002px) and (orientation: landscape) {
  #Tommy {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: var(--blue);
  }
}

@media screen and (orientation: landscape) {
  .mobileclass {
    display: none;
  }

  /* #Tommy{
    display: block;
  } */

}




.cls-1000 {
  font-size: 72px;
}

.cls-1000,
.cls-2000,
.cls-3000,
.cls-4000,
.cls-5000,
.cls-6000,
.cls-7000,
.cls-8000 {
  fill: #d3daca;
  text-anchor: middle;
}

.cls-1000,
.cls-3000,
.cls-8000 {
  font-family: "Maiandra GD";
}

.cls-2000,
.cls-8000 {
  font-size: 48px;
}

.cls-2000,
.cls-4000,
.cls-5000,
.cls-6000,
.cls-7000 {
  font-family: "Microsoft Sans Serif";
}

.cls-3000 {
  font-size: 24px;
}

.cls-4000 {
  font-size: 48.074px;
}

.cls-5000 {
  font-size: 30px;
}

.cls-6000 {
  font-size: 39.311px;
}

.cls-7000 {
  font-size: 56.989px;
}


.pac-container {
  z-index: 999999999999999;
}

.newProsearchxv1 {
  padding-left: 15px;
}


.inputselectcat>.css-1nxu04f-control,
.inputselectcat>.css-2mqrqd-control {
  border-radius: 4px !important;
}



.newProsearchxv1::-webkit-input-placeholder {
  font-size: 12px;
  padding: -220px;
}


* {
  scroll-margin-top: 85px;
}


.AlphaNav>a {
  /* min-width: 20px ; */
  list-style: none;
  text-decoration: none;
  /* outline-style: solid; */
  /* display: inline-block; */
}

.AlphaNav>a {
  text-align: center;
  min-width: 20px !important;
  text-decoration: none;
  color: black;
}


.grid-containerX {
  max-width: 660px;
  display: grid;
  /* grid-template-columns: auto auto auto auto; */
  grid-gap: 3px;
  /* background-color: #2196F3; */
  padding: 1px;
  grid-template-columns: repeat(13, 1fr);
}

.grid-containerX>a {
  cursor: pointer;
  color: black;
  /* background-color: rgba(214, 202, 202, 0.8); */
  text-align: center;
  font-size: 14px;

}

.grid-containerX>a:hover {
  color: black;
  background-color: rgba(214, 202, 202, 0.8) !important;

}

/* .item1 {
  grid-column: 1 / 13;
} */

.bluritnow {
  color: transparent !important;
  text-shadow: 0 0 15px #000 !important;
}

/*  Portfolio css  */

.postInput {

  width: 100%;
  border: none;
  min-height: 300px;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.LinkPejovjqepvj {
  text-decoration: none;
  color: var(--blue);
}

.postInput:active {
  outline: none;
  width: 100%;
  border: none;
  min-height: 300px;
  border: none;
  box-shadow: none;
}

.postIn {
  /* min-height: 50px; */
  margin-top: 0px;
  margin-bottom: 0px;
  height: 50px;
  border-radius: 30px;
  /* padding-top: 5px; */
  padding-left: 15px;
  line-height: 16px;
  font-size: 14px;
  width: 100%;
}

.posttoolbarcontainer {
  width: 64px;
}

.advertisementimgbtn {
  background-color: white;
  color: var(--blue);
  border: 1px solid var(--blue);
  margin: 3px;
  border-radius: 5px;
}

/* .eihfioehfoieh{
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  outline-color: transparent;
}
.eihfioehfoieh:active{
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  outline-color: transparent;
  outline: none;
  box-shadow: none;
  border :0px transparent;
} */

.modal {
  z-index: 100000000 !important;
}

@media only screen and (max-width:576px) {

  .posttoolbarcontainer {
    width: 54px;
  }

}

/* .modaltoolbar{
  height: 100px;
} */

.postMediacontainer {
  background-color: #2f2d30;
  width: 100%;
  /* height: 420px; */
  padding: auto;

}

.luide {
  position: relative;
  top: 50%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  max-width: 100%;
  max-height: 100%;

}

.toolbarbtn {
  border-color: var(--blue);
  color: var(--blue);
}

.redbtn {
  background-color: red;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.yellowbtn {
  background-color: yellow;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.greenbtn {
  background-color: green;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.btntext {
  display: none;
  position: fixed;
  margin-top: -21px;
  text-align: center;
}

.ride:hover .btntext {
  margin-left: -8px;
  display: block;
}

.ride2:hover .btntext {
  display: block;
}

.ride3:hover .btntext {
  display: block;
}

#DraftorPost {
  z-index: 2000;
}

/* The Modal (background) */
.modalt {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-contentt {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  padding-top: 0px;
  border: 1px solid #888;
  width: 80%;
}

.closet {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  width: fit-content;
  padding-left: 0px;
}

.backset {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  width: fit-content;
  padding-left: 0px;
}

.closet:hover,
.closet:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.aaa {
  background-color: #fff;
  text-align: center;
  height: 350px;
  padding: 10px;
}

.carItem {
  width: 100%;
  max-height: 340px;
  height: fit-content;
  object-fit: contain;
}


/* my product css */




.vertical {
  font-size: 20px;
}

.headding {
  font-size: 30px;
  color: var(--blue);
}

.car {
  margin-top: 10px;
}

h2 {
  font-weight: 900
}

.bg-pri {
  background-color: var(--blue);
  color: white;
}

.btn-war {
  background-color: var(--blue);
  color: white;
  border-radius: 30px;
}


.cardotp {
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  border: 0;
  border-radius: 1rem
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px)
}

.card h5 {
  overflow: hidden;
  height: 56px;
  font-weight: 900;
  font-size: 1rem
}

.card-img-top {
  width: 100%;
  max-height: 180px;
  object-fit: contain;
  padding: 30px
}

.card h2 {
  font-size: 1rem
}

.cardotp:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(199, 30, 30, 0.06)
}

.label-top {
  position: absolute;
  background-color: #8bc34a;
  color: #fff;
  top: 8px;
  right: 8px;
  padding: 5px 10px 5px 10px;
  font-size: .7rem;
  font-weight: 600;
  border-radius: 3px;
  text-transform: uppercase
}

.top-right {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 900;
  background: #ff5722;
  line-height: 90px;
  text-align: center;
  color: white
}

.top-right span {
  display: inline-block;
  vertical-align: middle
}

.fon {
  font-size: 14px;
}

.gtr {
  margin-left: -8px;
}

.btn-war:hover {
  color: white;
}

.btncheck {
  border-radius: 20px;
  background-color: var(--blue);
  color: white;

}

.btncheck:hover {
  color: white;
}

.checkboxpro {
  height: 30px;
  width: 30px;
  border: 2px solid var(--blue);
}

.checkboxpro:focus {
  box-shadow: none;
  outline: none;
}





.btnview {
  background-color: var(--blue);
  color: white;
  font-weight: 900;
}

.btnview:hover {
  border-color: var(--blue);
  background-color: white;
  color: var(--blue);
  font-weight: 900;
}

@media (max-width: 768px) {
  .card-img-top {
    max-height: 250px
  }
}

.over-bg {
  background: rgba(53, 53, 53, 0.85);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.0px);
  -webkit-backdrop-filter: blur(0.0px);
  border-radius: 10px
}

.btn {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 50px 5px 50px
}

.box .btn {
  font-size: 1.5rem
}

@media (max-width: 1025px) {
  .btn {
    padding: 5px 40px 5px 40px
  }
}

@media (max-width: 250px) {
  .btn {
    padding: 5px 30px 5px 30px
  }
}

.btn-warning {
  background: none #f7810a;
  color: #ffffff;
  fill: #ffffff;
  border: none;
  text-decoration: none;
  outline: 0;
  box-shadow: -1px 6px 19px rgba(247, 129, 10, 0.25);
  border-radius: 100px
}

.btn-warning:hover {
  background: none #ff962b;
  color: #ffffff;
  box-shadow: -1px 6px 13px rgba(255, 150, 43, 0.35)
}

.bg-success {
  font-size: 1rem;
  background-color: #f7810a !important
}

.bg-danger {
  font-size: 1rem
}

.price-hp {
  font-size: 1rem;
  font-weight: 600;
  color: darkgray
}

.amz-hp {
  font-size: .7rem;
  font-weight: 600;
  color: darkgray
}

.fa-question-circle:before {
  color: darkgray
}

.fa-plus:before {
  color: darkgray
}

.box {
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12)
}

.box-img {
  max-width: 300px
}

.thumb-sec {
  max-width: 300px
}

@media (max-width: 576px) {
  .box-img {
    max-width: 200px
  }

  .thumb-sec {
    max-width: 200px
  }
}

.inner-gallery {
  width: 60px;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 1px;
  display: inline-block;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover
}

@media (max-width: 370px) {
  .box .btn {
    padding: 5px 40px 5px 40px;
    font-size: 1rem
  }
}

.disclaimer {
  font-size: .9rem;
  color: darkgray
}

.related h3 {
  font-weight: 900
}

footer {
  background: #212529;
  height: 80px;
  color: #fff
}


/* Add Product Css  */




.containerkefnek {
  padding-top: 50px !important;
  border-radius: 20px
}

.btnehfi {
  letter-spacing: 1px
}

select:active {
  box-shadow: none !important;
  outline-width: 0 !important
}

select:after {
  box-shadow: none !important;
  outline-width: 0 !important
}

.eofeojfoeinput {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 0px !important;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2C3E50;
  font-size: 14px;
  letter-spacing: 1px;
  resize: none
}

select:focus,
input:focus {
  box-shadow: none !important;
  border: 1px solid var(--blue) !important;
  outline-width: 0 !important;
  font-weight: 400
}

label {
  margin-bottom: 2px;
  font-weight: bolder;
  font-size: 14px
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid var(--blue);
  outline-width: 0
}




.card-1 {
  border-radius: 17px;
  color: black;
  box-shadow: 2px 4px 15px 0px rgb(0, 0, 0, 0.5) !important
}

#file {
  border: 2px dashed var(--blue) !important
}

.color input {
  background-color: #f1f1f1
}

.files:before {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  content: attr(data-before);
  color: #000;
  font-size: 12px;
  font-weight: 600;
  text-align: center
}

#file {
  display: inline-block;
  width: 100%;
  padding: 95px 0 0 100%;
  background: url('https://i.imgur.com/VXWKoBD.png') top center no-repeat #fff;
  background-size: 55px 55px
}

.btn-pur {
  margin-top: 15px;
  background-color: var(--blue);
  color: #fff;
}

.btn-pur:hover {
  color: white;
}

.text {
  height: 80px;
}

.text:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid var(--blue);
  outline-width: 0
}

.modalfontcolor {
  text-decoration: none !important;
  color: white !important;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.fontonlobby {
  font-size: 0.9rem;
}

@media screen and (max-width:450px) {
  .fontonlobby {
    font-size: .8rem;
  }
}

@media screen and (max-width:410px) {
  .fontonlobby {
    font-size: .75rem;
  }
}

@media screen and (max-width:389px) {
  .fontonlobby {
    font-size: .7rem;
  }
}

@media screen and (max-width:373px) {
  .fontonlobby {
    font-size: .65rem;
  }
}

@media screen and (max-width:356px) {
  .fontonlobbysabbbbsss {
    font-size: .6rem !important;
  }
}

@media screen {}

/* @media screen and (max-width: 425px) {
  .fontonlobby {
    font-size: .9rem;
  }
}

@media screen and (max-width: 395px) {
  .fontonlobby {
    font-size: .8rem;
  }
}

@media screen and (max-width: 364px) {
  .fontonlobby {
    font-size: .7rem;
  }
} */




.blink_check:checked {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.spaceNoWrap {
  white-space: nowrap !important;
}

@media only screen and (max-width: 600px) {

  .pricingAllUni * {
    font-size: 9px;
  }

  .pricingAllUniPassive * {
    font-size: 10px;
  }

  .activPassiveBtn {
    font-size: 8px !important;
  }

  .activBtn {
    font-size: 7px !important;
  }

  .pricelasttextW {
    font-size: 11px;
  }

  .cstatusuf {
    font-size: 7px;
  }
}


@media only screen and (max-device-width: 900px) and (orientation: landscape) {

  .nonMemberUserFeatureMObileLandscape * {
    font-size: 11px;
  }
}

.activeSubscribeBtn {
  font-size: 9px !important;
}

#hoo1 * {
  font-size: 15px;
}


.SingleSelectableEach {
  background-color: #f6f7f5;

}

.SingleSelectableEach:hover {
  background-color: #d7d7d7;

}

.selectedE {
  background-color: #c1e0ff;
}

.selectedE:hover {
  background-color: #9cc3e9;
}


.cubiclebutton {
  background-color: var(--blue);
  height: 42px;
  width: 45px;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;

}


.cubiclebutton:hover {
  background-color: #55374ad1;
  height: 42px;
  width: 45px;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateY(-4px);
}

.cubiclebutton img {
  height: 21px;
}

.shareabel {
  font-size: 12px;
  text-align: center;
}

.centerProMax2-1 {
  position: relative;
  top: 50%;
  width: 100%;
  transform: translateY(-123%);
  text-align: center;
}

.centerProMax2-2 {
  position: relative;
  top: 50%;
  width: 100%;
  transform: translateY(-123%);
  text-align: center;
}



@media only screen and (max-width: 425px) {

  .centerProMax2-1 {
    transform: translateY(-55%) !important;
  }

  .centerProMax2-2 {
    transform: translateY(-160%) !important;
  }

}

.caro-pro-249882 .carousel-control-prev {
  background-color: black !important;
  height: 60px !important;
  top: calc(50% - 30px) !important;

}

.caro-pro-249882 .carousel-control-next {
  background-color: black !important;
  height: 60px !important;
  top: calc(50% - 30px) !important;


}


.caro-toolbarv2 {
  height: 300px;
}

/* .caro-pro-249882 .carousel-control-prev {
  position: absolute !important;
  top: 130px !important;
  display: flex !important;
}

.caro-pro-249882 .carousel-control-next{
  position: absolute !important;
  top: 130px !important;
  display: inline !important;
} */

.postView .postData {
  max-height: 400px !important;
}

.dropV2 {
  background-color: #55374a00 !important;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropv2Head .dropdown-toggle {
  /* display: none; */

}

.procxthe83{
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
background-color: #000000d4;
align-items: center;
justify-content: center;
}

.podfiowdf{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pddfg0{
  padding: 0px !important;
}