.rowPro {
    box-sizing: border-box;
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap;
    /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
}

.shower {
    display: block;
    z-index: 10000001;
    top: -29px;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.425);
}

/* Create four equal columnPros that sits next to each other */
.columnPro {
    box-sizing: border-box;
    -ms-flex: 25%;
    /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.columnPro img {
    box-sizing: border-box;
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    transition: 1s ease;
    cursor: pointer;


}
.inssmss{
    box-sizing: border-box;
    /* margin-top: 8px; */
    vertical-align: middle;
    width: 100%;
    height: 74vh;
    transition: 1s ease;
    cursor: pointer;
}
.columnPro video {
    box-sizing: border-box;
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    transition: 1s ease;
    cursor: pointer;


}

.columnPro>img:hover {
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    transition: 1s ease;
}
.columnPro>video:hover{
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    transition: 1s ease;
}

/* Responsive layout - makes a two columnPro-layout instead of four columnPros */
@media only screen and (max-width: 800px) {
    /* .columnPro {
        -ms-flex: 25%;
        flex: 25%;
        max-width: 25%;
    } */
}

/* Responsive layout - makes the two columnPros stack on top of each other instead of next to each other */
@media only screen and (max-width: 600px) {
    .columnPro {
        -ms-flex: 50%; 
        flex: 50%;
        max-width: 50%;
    }
}