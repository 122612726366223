/* body{
    margin-top:20px;
    color: #8e9194;
    background-color: #f4f6f9;
    } */
.text-muted {
    color: #aeb0b4 !important;
}

.text-muted {
    font-weight: 300;
}

.pro-spaces-button {
    border-color: var(--blue);
    background-color: transparent;
    color: var(--blue);
}

.pro-spaces-button:hover {
    border-color: var(--blue);
    background-color: transparent;
    color: var(--blue);
}



.pro-spaces-button2 {
    background-color: var(--blue);
    color: white;
    border-radius: 20px;
}

.centerProMax {
    padding: 0 !important;
    position: relative;
}

.centerProMax2 {
    position: relative;
    top: 50%;
    width: 100%;
    transform: translateY(-123%);
    text-align: center;
}
