
.top {
    margin-top: 70px;
}



.hrt {
    height: 500px;
    overflow-y: scroll;
}
 
.btnviewhis {
   background-color: #733380;
   color: white;
   border-radius: 20px;
}

.btnviewhis:hover {
    background-color: white;
    color: #733380;
    border: 1px solid #733380;
    font-weight: 700;
 }

 .btnviewhis:focus {
     box-shadow: none;
     outline: none;
 }

 .egfiegifgiegfiuegdbwlbclwu{
     padding-top: 90px;
 }
