@media only screen and (min-width: 1200px) {
    .cont{
        width: 800px;
    }
    }

    .spBtn{
        border: none;
        background-color: rgba(127, 255, 212, 0);
  
    }

    .boXe{
        width: min-content;
        height: 24px;
    }


    .btnpost {
        background-color: var(--blue);
        color: white;
    }
    
    .btnpost:hover {
        border: 1px solid var(--blue);
        background-color: white;
        color: var(--blue);
    }
    
    .btnpost:focus {
        box-shadow: none;
        outline: none;
    }
   