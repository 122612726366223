/* @font-face {
    font-family: MAIAN;
    src: url(./MAIAN.TTF);
  } */


.card-box-elite {
    /* padding: 20px; */

    /* background-color: #fff; */
    /* border-radius: 15px; */

    /* padding-top: 20px;
    border-width: 10px;
    border-image: linear-gradient(to bottom, #ffd700, #c4912a) 1;
    border-style: solid; */
    background-image: linear-gradient(to right, #CAAC60, #E8D597, #A98A3F) !important;

}

.img-thumbnail-elite {
    /* border: 1px solid #daa520 !important; */
    background-color: transparent !important;
}

.card-box {
    padding: 20px;

    background-color: #fff;
    border-radius: 15px;

    padding-top: 20px;
    border: 2px solid var(--blue);
}

.gmapiconbutton {
    border: 1px solid var(--blue);
    background-color: white;

}

.gmapicon {
    color: var(--blue);
}




.eofuvhoefhv {
    font-size: 13px;
}

.khksbvfwe {
    margin-top: -30px;
    margin-right: -30px;
}

.rigehioerhog {
    font-weight: 400;
}


.iorughoisrhgiohs {
    margin-top: 17px;
}


.ufghsuhie {
    color: #9e9999;
}

.puskaran2 {
    color: var(--blue);
    font-weight: 700;
}


@media screen and (max-width:845px) {
    .iorughoisrhgiohs {
        margin-top: 16px;
        margin-left: -10px;
    }
}



@media screen and (max-width:845px) {
    .khksbvfwe {
        margin-top: -45px;

    }
}

@media screen and (max-width:767px) {
    .khksbvfwe {
        margin-top: -30px;

    }
}




.social-links li a {
    border-radius: 50%;
    color: rgba(121, 121, 121, .8);
    display: inline-block;
    height: 30px;
    line-height: 27px;
    border: 2px solid rgba(121, 121, 121, .5);
    text-align: center;
    width: 30px
}

.social-links li a:hover {
    color: #797979;
    border: 2px solid #797979
}

.thumb-lg {
    height: 88px;
    width: 88px;
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
}

.bt2 {
    width: 105px;

}

.btnsabari {
    background-color: var(--blue);
    color: white;
}

.btnsabari:hover {
    background-color: var(--blue);
    color: white;
}



.fon {
    font-size: 14px;
}

.gtr {
    margin-left: -8px;
}

.btn3 {
    background-color: var(--blue);
    color: white;

}

.btn3:hover {

    color: white;
}

.text-pink {
    color: #ff679b !important;
}

.btn-rounded {
    border-radius: 2em;
}

.text-muted {
    color: #98a6ad !important;
}

h4 {
    line-height: 22px;
    font-size: 18px;
}


@media screen and (max-width:356px) {
    .font {
        font-size: 10px;
    }
}

.new-profilecard {
    text-decoration: none !important;
    border: 1px solid var(--blue);
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.new-profilecard .profilepic-new-card {
    width: 170px;
    height: 170px;
    /* border: 2px solid var(--blue); */
}

.border-top-newcard {
    /* border-top: 2px solid var(--blue); */
}

.borderBottom {
    border-bottom: 1px solid #d3d6d8;
}

.new-card-logo {
    position: absolute;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    left: -25px;
    bottom: 0;
}