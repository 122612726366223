@font-face {
    font-family: "Maiandra GD";

    src: url('../../MAIAN.TTF');

}

@font-face {
    font-family: "monoType";

    src: url('../../Monotype\ Corsiva.ttf');

}

.monotype {
    color: green;
    font-family: monoType;
    font-size: 30px;
}

.mir {

    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;
    top: 0;
    z-index: 100000000;
    font-family: 'Maiandra GD', sans-serif;
    height: 100% !important;
    background-color: #55374a;
    color: azure;
}

.mainContainerAnime {
    padding-top: 70px;
    /* position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    color: rgb(0, 0, 0);
    height: max-content;
    /* max-width: 600px; */
    color: azure;
    min-width: 300px;
}

.mainContainerAnime>span {
    display: block;
    color: azure;
    text-align: center;
}

.mainContainerAnime2 {
    padding: 5px;
    /* position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    color: rgb(0, 0, 0);
    height: max-content;
    /* max-width: 600px; */
    color: azure;
    min-width: 300px;
}

.mainContainerAnime2>span {
    display: block;
    color: azure;
    text-align: center;
}

.TitleAnime {
    font-size: 130px;
    animation: example forwards;
    color: azure;
    animation-duration: 3s;
    animation-delay: 1s;
    opacity: 0;
    margin-bottom: 30px;

}

.TitleAnimeIcon {
    animation: example forwards;
    animation-duration: 3s;
    animation-delay: 1s;
    opacity: 0;
    margin-bottom: 2%;
}

.line2Anime {
    font-size: 40px;
    /* letter-spacing: 6px; */
    /* word-spacing: 25px; */
    animation: example forwards;
    animation-delay: 3s;
    color: azure;
    animation-duration: 2s;
    opacity: 0;
}

.line3Anime {
    font-size: 35px;
    /* letter-spacing: 1px;
    word-spacing: 1px; */
    animation: example forwards;
    animation-delay: 3s;
    color: azure;

    animation-duration: 2s;
    opacity: 0
}

.line4Anime {
    color: azure;
    margin-top: 40px;
    font-size: 25px;
    /* letter-spacing: 4px; */
    animation: example forwards;
    animation-delay: 4s;

    animation-duration: 3s;
    opacity: 0
}

.line5Anime {
    color: azure;
    margin-top: -5px;
    font-size: 25px;
    /* letter-spacing: 2px; */
    animation: example forwards;
    animation-delay: 4s;
    animation-duration: 3s;
    opacity: 0
}


.proaniminaruto {
    width: inherit;
    position: absolute;
    bottom: 10px;
    z-index: 100000000;
    display: flex;
    justify-content: center;
}

.line6Anime {

    font-family: 'Maiandra GD', sans-serif;
    z-index: 1000000000;
    color: white;
    text-align: center;
    font-size: 25px;
    letter-spacing: 2px;
    animation: example forwards;
    animation-delay: 4s;

    animation-duration: 3s;
    opacity: 0
}

/* chaging */

.line7Anime {
    font-family: 'Maiandra GD', sans-serif;
    z-index: 1000000000;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    animation: example linear infinite;
    animation-delay: 5s;
    animation-duration: 1s;
    opacity: 0
        /* animation-duration: 3s;opacity: 0 */
}

.LauchContainer {
    /* height: 100%;
    position: relative; */
}

.line7Anime {
    /* position: absolute;
    top: 0;
    bottom: 0; */
}

@keyframes blinker {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}


@keyframes example {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.25;
    }

    50% {
        opacity: 0.50;
    }

    100% {
        opacity: 1;
    }
}

.strikeAnime {
    width: 100%;
    height: 2px;
    margin-top: -85px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    animation: mainblockanimationtry 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    ;
    animation-duration: 2s;
}

@keyframes mainblockanimationtry {
    0% {
        width: 0%;
        left: 0;
    }

    50% {
        width: 100%;
        left: 0;
    }

    100% {
        width: 0;
        left: 100%;
    }
}


@media only screen and (max-width: 600px) {

    .strikeAnime {
        width: 100%;
        height: 2px;
        margin-top: -60px;
        position: absolute;
        background-color: rgb(255, 255, 255);
        animation: mainblockanimationtry 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        ;
        animation-duration: 2s;
    }

    .TitleAnime {
        font-size: 80px;
    }

    .line2Anime {
        font-size: 20px;
        /* letter-spacing: 5px; */
        /* word-spacing: 14px; */
    }

    .line3Anime {
        font-size: 19px;
        /* letter-spacing: 2px;
        word-spacing: 0px; */
    }

    .line4Anime {
        font-size: 15px;
    }

    .line5Anime {
        font-size: 16px;
    }

    .line6Anime {
        font-size: 16px;
    }
}



@media only screen and (min-width: 601px) {



    .TitleAnime {
        font-size: 90px;
    }

    .line2Anime {
        font-size: 25px;
        /* letter-spacing: 5px; */
        /* word-spacing: 14px; */
    }

    .line3Anime {
        font-size: 24px;
        /* letter-spacing: 2px;
        word-spacing: 0px; */
    }

    .line4Anime {
        font-size: 20px;
    }

    .line5Anime {
        font-size: 21px;
    }

    .line6Anime {
        font-size: 21px;
    }
}