/* body{
    background:#DCDCDC;
    
} */

.aswq {
    position: sticky;
    top: 30px;
    
}

.yut {
    border: 2px solid var(--blue);
}

.cardjbgwebogwe {
    border: 3px solid var(--blue);
    margin-top: 70px;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.back {
    text-decoration: none;
    color: var(--blue);
}
.back:hover {
    text-shadow: 2px 2px 5px var(--blue);
    color: var(--blue);
}

.product {
    background-color: #eee;
    height: 100%;
    border-radius: 25px;
}

.btn-color {
    background-color: var(--blue);
    color: white;
}

.brand {
    font-size: 13px
}

.act-price {
    color: var(--blue);
    font-weight: 700
}

.dis-price {
    text-decoration: line-through
}

.about1 {
    font-size: 14px
}

.color {
    margin-bottom: 10px
}

label.radio {
    cursor: pointer
}

label.radio input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none
}

label.radio span {
    padding: 2px 9px;
    border: 2px solid #ff0000;
    display: inline-block;
    color: #ff0000;
    border-radius: 3px;
    text-transform: uppercase
}

label.radio input:checked+span {
    border-color: #ff0000;
    background-color: #ff0000;
    color: #fff
}

.btn-danger {
    background-color: #ff0000 !important;
    border-color: #ff0000 !important
}

.btn-danger:hover {
    background-color: #da0606 !important;
    border-color: #da0606 !important
}

.btn-danger:focus {
    box-shadow: none
}

.cart i {
    margin-right: 10px
}