.moviesinglelineheadline{
    display: block; 
    display: -webkit-box;
    max-width: 400px;
    height: 24*1*1;
    margin: 0 auto;
    font-size: 24;
    line-height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.moviereadmore{
    display: block; 
    display: -webkit-box;
    max-width: 400px;
    height: 14*1*1;
    margin: 0 auto;
    font-size: 14;
    line-height: 1;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}