.fffnhfyfkneohf {
    color: var(--blue);
    position: fixed;
    top: 50%;
    left: 57%;
    transform: translate(-50%, -50%);

}


@media screen and (max-width:572px) {
    .fffnhfyfkneohf {
        color: var(--blue);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
    }
}