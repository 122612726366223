body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pac-container {
  z-index: 999999999999999 !important;
}

.pac-logo {
  z-index: 999999999999999 !important;
}

.adControlls {
  top: 29%;
  left: calc(50% - 60px);
  position: absolute;


}
.adControlls{
  display: none;
}
.adImage236326{
  display: flex;
  justify-content: center;
}
.adContainer020:hover .adControlls{
  display: block !important; 
}