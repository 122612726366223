

.btn-quickhj {
    background-color: var(--blue);
    color: white;
    border-radius: 20px;
}

.btn-quickhj:hover{
    color: white;
}

.btn-quickhj:focus{
    outline: none;
    box-shadow: none;
}

.searchforsubcategory{
    padding-left: 20px;
    height: 40px;
    border-radius: 25px;
    border: 1px solid var(--blue);
    margin-bottom: 15px;
}

.parapara{
    color: var(--blue);
}

.parapara22{
    color:#c85366;
}
.weigh3rohbiwhbroiwehg{
    position: absolute;
    right: 25px;
}
.wekjgvuckugeqqjeqeojceucgh{
    z-index: 99999999999;
    color: white;
}

.gtyurie {
    /* width: 350px; */
    border: 1;
    border-radius: 15px;
   
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    cursor: pointer;
    /* margin-top: 70px; */
    /* margin-left: -25px; */
}

.gtyurie:hover {
    color: white;
    background-color: var(--blue);
   
    
}

.icon {
    text-align: center;
    font-size: 40px;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}



.btnbhg {
    background-color: var(--blue);
    color: white;
    border-radius: 20px;
   
    font-size: 10px;
    
}



.btnbhg:hover {
    background-color: white;
    color: var(--blue);
}

.btnbhg:focus {
    box-shadow: none;
    outline: none;
}

pre {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.linkoflink {
    text-decoration: none;
    color: black;
}

.linkoflink:hover{
    color: white;
}

.wieugfiqgefnqenc2rnewiuhgiuhq{
    padding-top: 80px;
}


@media screen and (min-width:577px) and (max-width:1300px) {
    .pro-sabari{
        padding-left: 0px;
    }
}






