

.navContent {
    
  margin-top: -20px;
  position: fixed;
  width: 300px;
  margin-left: -15px;
  background: var(--blue);
  height: 103vh;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  

}

@media screen and (min-width:320px) and (max-width:520px) {
  .weiuogweiutieufwhgiw{
    margin-top: 50px;
  }
  
}


.ekurfvhouwhrvilwbroufvgwruovwrojvpwrhjv{
  width: 450px;
}
@media screen and (max-width:418px) {
  .ekurfvhouwhrvilwbroufvgwruovwrojvpwrhjv{
    width: 350px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navContent::-webkit-scrollbar {
display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navContent {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.rty {


height: 100vh;
overflow-y: hidden;
z-index: 20000;
}

.lkjh{
position: absolute;
right: 0;
top: 0;

}




/* .whi {
margin-top: -20px;
position: fixed;
width: 10px;
margin-left: -15px;
background: white;
height: 103vh;
color: white;
} */

.link {
text-decoration: none;
color: #f1f1f1;
margin-top: -50px;
}

.link:hover {
color: rgb(255, 215, 0);
}

.font {
font-size: 15px;
font-weight: 500;
}

.gold:hover {
color: rgb(255, 215, 0);

}

.lin {
  /* display: grid; */
  text-align: center;
}

#first {
  display: block;
}

#secoend {
  display: none;
}

#go:hover #first {
  display: none;
}

#go:hover #secoend {
  display: block;
}

#imgpadding {
  margin-left: 128px;
}

.icon1 {
margin-top: 100px;
font-size: 18px;
text-align: center;

}




.icon2 {
margin-top: 100px;
font-size: 18px;
text-align: center;

}


.icon3 {
margin-top: 15px;
font-size: 18px;
text-align: center;
}

.icon31 {
margin-top: 15px;
font-size: 18px;
text-align: center;
}


.icon4 {
margin-top: 15px;
font-size: 18px;
text-align: center;

}

.icon5 {
margin-top: 15px;
font-size: 18px;
text-align: center;

}

.titles {
font-size: 20px;
font-weight: 500;

}













@media screen and (max-width:1300px){
  .navContent{
    width: 170px;
  }
  

  .icon2{
    margin-top: 15px;
    
  }

  .icon5 {
    margin-top: 15px;
  }

  .icon3 {
      width: 155px;
  }
  #imgpadding {
      margin-left: 55px;
  }

  
  
 
 
}


@media only screen  and (max-width:1300px){

  .icon2{
      margin-top: 12px;
      font-size: 20px;
    }
 
    .icon5 {
      margin-top: 12px;
      font-size: 20px;
    }

    .icon3 {
      margin-top: 12px;
        width: 155px;
        font-size: 20px;
    }

    .icon1 {
      margin-top: 90px;
      font-size: 20px;
    }

    .icon4{
      margin-top: 12px;
      font-size: 20px;
    }
    .font{
      font-size: 13px;
      
    }.dmsfoeeqofqjogfsnglvmsdv{
      padding-left: 10px;
    }

}


.gdiwhfwihfiwh{
  margin-top: -5px;
}




.fighoihoighoihs{
  text-decoration: none;
  color: white;
  
}

.fighoihoighoihs:hover{
  color: white;
}

.shsiohgd{
  padding-top: 100px;
}


@media screen and (max-width:1950px) {
  .fighoihoighoihs{
    font-size: 12px;
  }
  .rbkjwbrkvb{
    font-size: 20px;
  }
}