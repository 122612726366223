.boom {
    padding-top: 100px;
}

:root {
    --blue :var(--blue);
    --white: #fff;
    --gray:#f5f5f5;
    --black1:#222;
    --black2:#999;
}





.cardBoxii {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    /* grid-template-columns: repeat(4,1fr); */
    grid-gap: 30px;
    margin-top: 100px;
    display: table;

    

}
.tefnmbdfmpbompdmb {
    text-decoration: none;
}
.cardBoxi .cardfgt {
    position: relative;
    background: var(--white);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 7px 25px rgba(0,0,0,0.4);
    /* height: 415px; */
    width: 596px;
    min-width: 300px;
   display: table-cell;
   vertical-align: middle;
    

}

.cardBoxi .cardjob .numbers {
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--blue);
}

.cardBoxi .cardjob .cardName {
    color: var(--black2);
    font-size: 1.1em;
    margin-top: 5px;
}

.cardBoxi .cardjob .iconBox {
    font-size: 3.5em;
    color: var(--black2);
}

.cardBoxi .cardjob:hover {
    background: var(--blue);
}

.cardBoxi .cardjob:hover .numbers ,
.cardBoxi .cardjob:hover .cardName a,
.cardBoxi .cardjob:hover .iconBox {
     color: var(--white);
}



.cardName ul li a {
    text-decoration: none;
    color: var(--blue);
}



@media screen and  (max-width:550px) {
    .cardfgt{
       font-size: 13px;
       
    }
    .cardBoxi{
margin-top: 10px;
    }
}

@media screen and  (max-width:1489px) {

    .rgkerpkgbepkgpetkg{
height: 300px;
    }
}

@media screen and  (max-width:1024px) {

    .rgkerpkgbepkgpetkg{
height: 359px;
    }
}

@media screen and  (max-width:926px) {

    .rgkerpkgbepkgpetkg{
height: 418px;
    }
}
@media screen and  (max-width:779px) {

    .rgkerpkgbepkgpetkg{
height: 418px;
    }
}
@media screen and  (max-width:767px) {

    .rgkerpkgbepkgpetkg{
height: auto;
    }
}


.margintopof100px{
    margin-top: 100px;
}
