/* body{
    background:#DCDCDC;
  
} */

.twedfqtfudc {
    text-decoration: none;
    color: black;
}

.twedfqtfudc:hover {
    color: white;
}


.btn-quickhj {
    background-color: var(--blue);
    color: white;
    border-radius: 20px;
}

.btn-quickhj:hover {
    color: white;
}

.btn-quickhj:focus {
    outline: none;
    box-shadow: none;
}

.carderghibgibgp {
    /* width: 350px; */
    border: 1;
    border-radius: 15px;
    background-color: white;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    cursor: pointer;
    /* margin-top: 70px; */
    /* margin-left: -25px; */
}

.carderghibgibgp:hover {
    background-color: var(--blue);
    color: white;
}

.icon {
    text-align: center;
    font-size: 40px;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn2:hover {
    background-color: white;
    color: black;
}

.btnbhg {
    background-color: var(--blue);
    color: white;
    border-radius: 20px;

    font-size: 10px;

}

.btnbhg:hover {
    background-color: white;
    color: var(--blue);
}

.btnbhg:focus {
    box-shadow: none;
    outline: none;
}

pre {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}




#ck-button {
    border-radius: 4px;
    /* border:1px solid #D0D0D0; */
    overflow: auto;
    float: left;
}

#ck-button label {
    float: left;
    width: 100%;
    text-transform: capitalize;
}

#ck-button label span {
    text-align: center;
    padding: 0px 0px;
    display: block;
    border-radius: 4px;
}

#ck-button label input {
    position: absolute;
    top: -20px;
}

#ck-button input:hover+span {
    background-color: #efE0E0;
}

#ck-button input:checked+span {
    background-color: var(--blue);
    color: #fff;
    animation: blinker2 1s linear infinite;
}

#ck-button input:checked:hover+span {
    background-color: var(--blue);
    color: #fff;
    animation: blinker2 1s linear infinite;
}

@keyframes blinker2 {
    50% {
        opacity: 0;
    }
}



.btn-rounded {
    border-radius: 2em;
}