
.pricebuttoncolor{
    background-color: #55374a;
    color: white;
}
.dgeef{
    left: 0;
}


.wlgwligwihgif{
    height: 1000px;
}

.wrohmrwomwrgowgmlosabvcd{
    font-size: 18px;
}

@media only screen and (max-width: 992px)  {
    .kvheihviehv{
        display: none;
    }
}
@media only screen and (max-width: 690px)  {
    .mangandiiimode{
        display: none;
    }
}

.blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

