

.form-control:focus {
    box-shadow: none;
    border-color: var(--blue) 
}

.form-select:focus {
    box-shadow: none;
    border-color: var(--blue)
}

.profile-button {
    background: var(--blue);
    box-shadow: none;
    border: none
}

.profile-button:hover {
    background: var(--blue)
}

.profile-button:focus {
    background: var(--blue);
    box-shadow: none
}

.profile-button:active {
    background: var(--blue);
    box-shadow: none
}

.back:hover {
    color: var(--blue);
    cursor: pointer
}

.labels {
    font-size: 11px,
    
}

.add-experience:hover {
    background: var(--blue);
    color: #fff;
    cursor: pointer;
    border: solid 1px var(--blue)
}



.upload {
    color: var(--blue);
    background-color: rgba(0, 0, 0, 0.397);
    margin-top: -50px;
    width: 150px;
   
}

.up {
    color: var(--blue);
    cursor: pointer;
}