.boom {
    padding-top: 100px;
}

:root {
    --blue :#55374a;
    --white: #fff;
    --gray:#f5f5f5;
    --black1:#222;
    --black2:#999;
    /* --display: none !important; */
}





.cardBoxii {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    /* grid-template-columns: repeat(4,1fr); */
    grid-gap: 30px;
    margin-top: 100px;
    display: table;

    

}
.tefnmbdfmpbompdmb {
    text-decoration: none;
}
.cardBoxi .cardfgt {
    position: relative;
    background: var(--white);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 7px 25px rgba(0,0,0,0.4);
    /* height: 415px; */
    width: 596px;
    min-width: 300px;
   display: table-cell;
   vertical-align: middle;
    

}

.cardBoxi .cardjob .numbers {
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--blue);
}

.cardBoxi .cardjob .cardName {
    color: var(--black2);
    font-size: 1.1em;
    margin-top: 5px;
}

.cardBoxi .cardjob .iconBox {
    font-size: 3.5em;
    color: var(--black2);
}

.cardBoxi .cardjob:hover {
    background: var(--blue);
}

.cardBoxi .cardjob:hover .numbers ,
.cardBoxi .cardjob:hover .cardName a,
.cardBoxi .cardjob:hover .iconBox {
     color: var(--white);
}



.cardName ul li a {
    text-decoration: none;
    color: var(--blue);
}

.carrrrbusss {
    margin-top: 50px;
   position: relative;
   background: var(--white);
   padding: 30px;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 7px 25px rgba(0,0,0,0.4);
   
}

.carrrrbusss:hover {
   background-color: var(--blue);
   color: white;
}

.carrrrbusss2 {
    margin-top: 50px;
   position: relative;
   background: var(--white);
   padding: 30px;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 7px 25px rgba(0,0,0,0.4);
   
}

.carrrrbusss2:hover {
   background-color: var(--blue);
   color: white;
}


.slhishieo3jfiehf{
    padding-top: 100px;
}


.padunnorssd{
    font-size: 20px;
}

@media screen and (min-width: 1558px) {
   
    .carrrrbusss {
        height: 200px;
    }
    .carrrrbusss2 {
        height: 200px;
    }
 }

@media screen and (max-width: 1558px) {
   
   .carrrrbusss {
       height: 200px;
   }
   .carrrrbusss2 {
    height: 200px;
}
}

@media screen and (max-width: 1010px) {
   
   .carrrrbusss {
       height: 300px;
   }
   .carrrrbusss2 {
    height: 300px;
}
}

@media screen and (max-width: 803px) {
   
   .carrrrbusss {
       height: 300px;
   }
   .carrrrbusss2 {
    height: 300px;
}
}

@media screen and (max-width: 767px) {
   
    .carrrrbusss {
        height: 250px;
    }.carrrrbusss2 {
        height: 250px;
    }
 }



@media screen and (max-width: 700px) {
   
   .carrrrbusss {
       height: 250px;
   }
   .carrrrbusss2 {
    height: 250px;
}
}

@media screen and (max-width: 576px) {
   
   .carrrrbusss {
       height: 200px;
   }
   .carrrrbusss2 {
    height: 200px;
}
}

@media screen and (max-width: 480px) {
   
    .carrrrbusss {
        height: 200px;
    }
    .carrrrbusss2 {
        height: 100px;
    }
 }

@media screen and  (max-width:550px) {
    .cardfgt{
       font-size: 13px;
       
    }
    .cardBoxi{
margin-top: 10px;
    }
}

@media screen and  (max-width:1489px) {

    .rgkerpkgbepkgpetkg{
height: 300px;
    }
}

@media screen and  (max-width:1024px) {

    .rgkerpkgbepkgpetkg{
height: 359px;
    }
}

@media screen and  (max-width:926px) {

    .rgkerpkgbepkgpetkg{
height: 418px;
    }
}
@media screen and  (max-width:779px) {

    .rgkerpkgbepkgpetkg{
height: 418px;
    }
}
@media screen and  (max-width:767px) {

    .rgkerpkgbepkgpetkg{
height: auto;
    }
}




