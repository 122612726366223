
.card-box {
    padding: 40px;
    
    background-color: #fff;
    border-radius: 15px;
    
    
   
}







.social-links li a {
    border-radius: 50%;
    color: rgba(121, 121, 121, .8);
    display: inline-block;
    height: 30px;
    line-height: 27px;
    border: 2px solid rgba(121, 121, 121, .5);
    text-align: center;
    width: 30px
}

.social-links li a:hover {
    color: #797979;
    border: 2px solid #797979
}
.thumb-lg {
    height: 88px;
    width: 88px;
}
.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
}

.bt2 {
    width: 105px;
    
}



.fon {
    font-size: 14px;
}

.gtr {
    margin-left: -8px;
}

.btn3 {
    background-color: var(--blue);
    color: white;
    width: 120px;
}

.btn3:hover {
    
    color: white;
}

.text-pink {
    color: #ff679b!important;
}
.btn-rounded {
    border-radius: 2em;
}
.text-muted {
    color: #98a6ad!important;
}
h4 {
    line-height: 22px;
    font-size: 18px;
}


@media screen and (max-width:356px)  {
    .font {
        font-size: 10px;
    }
}