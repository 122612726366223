


.soniyaaaaaaa {
    /* background: #e2dcd8; */
    background: white;

}
.page-wrap {
    min-height: 100vh;
}