



.card111 {
    width: 700px;
    border: 3px solid var(--blue);
    margin-top: 110px;
    
    
}




.header {
    color: white;
    background-color: var(--blue)
}

.textareafeed {
    border-color: var(--blue);
    box-shadow: none !important;
    -webkit-appearance: none;
    outline: 0px !important;
    
}

.btncolj{
    background-color: var(--blue);
    color: white;
}

.btncolj:hover{
    color: #f4f7f6;
}



@media screen and (max-width:888px) {
    .card111 {
        width: 550px;
        
    }
    
}

@media screen and (max-width:740px) {
    .card111 {
        width: 450px;
        
    }
   
}

@media screen and (max-width:668px) {
    .card111 {
        width: 400px;
        
    }
    
}







.carrrrbuss {
    margin-top: 30px;
   position: relative;
   background: var(--white);
   padding: 30px;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 7px 25px rgba(0,0,0,0.4);
   
}


.wgfiuwrgiuwdjbcb{
    color: var(--blue);
    text-decoration: none;
}

/* 
.dkhgsusrghoiusrhgosrio{
    align-items: center;
    max-width: 500px;
} */







