.cardBox11 {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    /* grid-template-columns: repeat(4,1fr); */
    grid-gap: 30px;
    margin-top: 50px;
}

.wgfiuwrgiuddd{
    color: #55374a;
    text-decoration: none;
}
.nefhoehfliwrhvguwrogfvowrufv{
    height: 100vh;
    overflow-y: scroll;
}
.wgfiuwrgiuddd:hover{
     color: white;
}

.numberser {
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--blue);
}.numberser:hover{
    color: white;
}

 .carrrrrr {
     margin-top: 50px;
    position: relative;
    height: 350px;
    background: var(--white);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 7px 25px rgba(0,0,0,0.4);
    
}

.magiccard {
    width: 100%;
    margin-top: 25px;
   position: relative;
 
   background: var(--white);
   padding: 20px;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 7px 25px rgba(0,0,0,0.4);
   
}




.slhishieo3jfiehf9999{
    margin-top: 90px;
}




@media screen and (max-width: 1558px) {
    
    .carrrrrr {
        height: 450px;
    }
}

@media screen and (max-width: 1010px) {
    
    .carrrrrr {
        height: 450px;
    }
}

@media screen and (max-width: 892px) {
    
    .carrrrrr {
        height: 450px;
    }
}

@media screen and (max-width: 768px) {
    
    .carrrrrr {
        height: 430px;
    }
}




@media screen and (max-width: 689px) {
    
    .carrrrrr {
        height: 400px;
    }
}

@media screen and (max-width: 585px) {
    
    .carrrrrr {
        height: 540px;
    }
}



@media screen and (max-width: 490px) {
    
    .carrrrrr {
        height: 500px;
    }
}

@media screen and (max-width: 424px) {
    
    .carrrrrr {
        height: 550px;
        margin-top: 25px;
    }
    /* .slhishieo3jfiehf9999{
        padding-top: 50px;
    } */
}

