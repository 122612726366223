.searchInput{
    background-color: var(--blue);
    color: white;
    font-weight: 600;
    height: 50px;
    border-radius: 50px;
    width: 100%;
    padding-left: 15px;
    padding-right: 50px;
   
}
.searchIco{
    margin-left: -50px;
    margin-top: -10px;
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    border: none;
}

.searchInput:active{
    color: white;
    border: none;
   
}
.searchInput:focus{
    color: white;
   box-shadow: none;
   border: none;
   outline: none;
}
.searchInput::placeholder{
    color: white;
   box-shadow: none;
   border: none;
}

.Scont{
    position: relative;

}

.searchInput{
  
    border: 1px solid rgb(255, 255, 255);
}

#Subcategory:active,.searchInput:active{  border-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(214, 12, 170, 0.6);
    outline: 0 none;
}

#Subcategory:hover,.searchInput:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(232, 12, 240, 0.6);
    outline: 0 none;
}
#Subcategory:focus,.searchInput:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(232, 12, 240, 0.6);
    outline: 0 none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#AdvancedSearch{
    border: 2px solid var(--blue) ;
    border-radius: 20px;
    
}

.btnvgy {
    background-color: var(--blue);
    color: white;
    
}