/* body{
    background:#DCDCDC;
    margin-top:20px;
} */

.sdvshfihfihf {
    /* width: 350px; */
    border: 1;
    border-radius: 15px;
    background-color: white;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    cursor: pointer;
    /* margin-top: 70px; */
    /* margin-left: -25px; */
}

.sdvshfihfihf:hover {
    background-color: var(--blue);
    color: white;
}

.icon {
    text-align: center;
    font-size: 40px;
}

.cendvkdskvster {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .btn2:hover{
  background-color: white;
  color: black;
} */

.btn4rmgrk {
    background-color: var(--blue);
    color: white;
}

.btn4rmgrk:hover {
    background-color: white;
    color: black;
}







 

.btn-rounded {
    border-radius: 2em;
}


