.btnpost {
    background-color: var(--blue);
    color: white;
}

.btnpost:hover {
    border: 1px solid var(--blue);
    background-color: white;
    color: var(--blue);
}

.btnpost:focus {
    box-shadow: none;
    outline: none;
}

.dslihgluho{
    padding-top: 70px;
}

.btnanimat{
   
    border: 1px solid var(--blue);
    animation: mymove .5s infinite;
}

.btnanimat:hover{
   background-color: var(--blue);
   color: white;
    border: 1px solid var(--blue);
    animation:none;
}

@keyframes mymove {
    0%   {background: var(--blue) ; color: white;}
    100% { background: white; color:var(--blue); }
  }