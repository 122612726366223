.dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
}

.dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
}

.alieifhoiaeh{
    max-width: 500px;
    word-break: break-all;
}
.dkufgiwfiuwbcuwebcu{
    text-decoration: none;
    
}

.activenoti{
    color: white;
}
.activenoti:hover{
    color: white;
}
.notnoti{
    color: black;
}
.notnoti:hover{
    color: black;
}

.btn-light {
    /* color: #2cdd9b; */
    background-color: #e5f7f0;
    border-color: #d8f7eb;
}

*, ::after, ::before {
    box-sizing: none;
}

.text-truncate {
    white-space: normal;
}

.notificationItem:hover {
    background-color: rgb(168, 164, 164);
}

.notificationItem:active {
    background-color: rgb(255, 255, 255);
}

.unseen {
    background-color: rgb(111, 216, 216);
}

.notificationBg {
    top: 0ch;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* border:  1px solid; */
    /* height: 100vh; */
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 10px;
    width: 100%;
    min-width: 230px;
   

}

.esgjpisehgpishepgsip{
    height: calc(100vh - 100px);
}

.notificationContainer {
    width: 100%;
}

.notificationBg::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .notificationBg {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }




  .weingoucerh9guoheuozgb3o{
background-color: var(--blue);
color: white;
  }






@media only screen and (min-width : 321px) {

    .text-truncate {
        font-size: 12px;
    }

}