.serrow {
    margin-left: 290px;
}

.dfrty{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dfrty::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .dfrty {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }



.notificationrt{
    /* width: 30%; */
    position: fixed;
    right: 0;
    
}

.ijfsvwijiviwrvnvnjw{
    padding-left: 11px;
    padding-right: 0px;
}

@media screen and (max-width:992px){
    .ijfsvwijiviwrvnvnjw{
        padding-right: 15px;
    }
}

@media screen and (max-width:576px){
    .ijfsvwijiviwrvnvnjw{
        padding-left: 0px;
        padding-right: 0px;
    }
}




@media screen and (max-width:1300px) {
    .serrow {
        margin-left: 170px;
    }
    .dfrty{
        padding-left: 0px;
    }

 }

 @media screen and (max-width:1100px) {
    

 }
 @media screen and (max-width:950px) {
   
    .notificationrt{
        width: 40%;
    }

 }

 @media screen and (max-width:767px) and (min-width:700px) {
    
    .notificationrt{
        width: 40%;
        
    }

 }

 @media screen and (max-width:700px) {
    
    .notificationrt{
        display: none;
        
    }

 }

 @media screen and (max-width: 576px) {
     .serrow{
         margin-left: 0px;
     }
     .btn4 {

     }
 }