
.toppp {
    padding-top:50px;
}

.fiwjgpjwjgwjgpwjfpxmpxm{
    padding-top:80px;
}

.headdingg {
    font-size: 40px;
    font-weight: 500;
}
.lissss {
    /* height: fit-content; */
    overflow-y: scroll;
}

.slrghohcognherough{
    height: 38px;
}

.iewjgpiwjpjwepofj{
    color: var(--blue);
}

.eroigheorihg{
    height: 70vh;
}

.eiugfwieugfiuaegf{
    max-height: 40px;
}





  /* ::-webkit-scrollbar {
    width: 20px;
  } */
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--blue); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--blue); 
  }

.vs {
    color: var(--blue);
}
.btn:focus, .btn:active:focus, .btn.active:focus{
    outline:none;
    box-shadow:none;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: 0 !important;
}

.btn-vs {
    background-color: var(--blue);
    color: white;
    border:2px solid var(--blue);
    font-weight: 500;
}
.btn-vs:hover {
    background-color: white;
    color: var(--blue);
    border:2px solid var(--blue);
    font-weight: 900;
    
}
.btn-vsi {
    background-color: white;
    color: var(--blue);
    border:2px solid var(--blue);
    font-weight: 500;
}

.zise {
    font-size: 25px;
    

}

.eye {
    font-size: 25px;
}




.form-check-input:checked {
    background-color: var(--blue);
    border-color: var(--blue);
}

.fd {
    color: green;
    font-size: 20px;
    font-weight: 600;
}
.strike {
    color: var(--blue);
    text-decoration: line-through;
}

.eds {
    border: 1px solid var(--blue);
}
.task {
    color: var(--blue);
}

.add-todo-input,
.edit-todo-input {
    outline: none;
}

.add-todo-input:focus,
.edit-todo-input:focus {
    border: none !important;
    box-shadow: none !important;
}

.view-opt-label,
.date-label {
    font-size: 0.8rem;
}

.edit-todo-input {
    font-size: 1.7rem !important;
}

.todo-actions {
    visibility: hidden !important;
}

.todo-item:hover .todo-actions {
    visibility: visible !important;
}

.todo-item.editing .todo-actions .edit-icon {
    display: none !important;
}
