.dkfhdhf {
    padding-left: 10%;
}

#termsofuserfgu .pos {
    text-align: justify;
}

.monotypepolicies {
    color: green;
    font-family: monoType;
}