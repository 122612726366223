.newNavBackground{
    position: fixed;
    top: 0;
    background-color: var(--blue);
    height: 70px;
    width: 100vw;
    z-index: 1500;

}



.toogleButton{
   position: fixed;
   right: 0;
}

.offcanvasheightfornotications{
    height: calc(100vh - 70px);
    margin-top: 70px;
}
