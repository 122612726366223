.linkoflinkk{
    color: black;
    text-decoration: none;
}

.linkoflinkk:hover{
    color: white;

}
.bgtydvnsu {
    /* width: 350px; */
    border: 1;
    border-radius: 15px;
    background-color: white;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    cursor: pointer;
    /* margin-top: 70px; */
    /* margin-left: -25px; */
}

.bgtydvnsu:hover {
    background-color: #55374a;
    color: white;
}

.icon {
    text-align: center;
    font-size: 40px;
}

.centerwvnwrhnvoiwqn {
    display: flex;
    align-items: center;
    justify-content: center;
}



.btn4ert {
    background-color: #55374a;
    color: white;
}

.btn4ert:hover {
    background-color: white;
    color: black;
}

.wrjbgviuwrboncni {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.btn-rounded {
    border-radius: 2em;
}






