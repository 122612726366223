@media screen and (min-width:450px) {
    .lobbyudayippp {
        display: none;
    }
}


.imageonlobbycard {
    width: 100%;
    max-height: 188px;
    object-fit: cover;
}

.loby-page b {
    color: var(--blue);
}

.bodyofacard {
    flex: 1 1 auto;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.askkdfjkhf:hover .img-overlaysss {
    display: block;
}

.img-overlaysss {
    display: none;
    position: absolute;
    top: 40%;
    /* bottom: 0; */
    left: 0;
    right: 0;
    text-align: center;
}

.img-overlaysss:before {
    content: ' ';
    display: block;
    /* adjust 'height' to position overlay content vertically */
    height: 50%;
}



#react-select-2-input {
    border: 0 !important;
}

#react-select-3-input {
    border: 0 !important;
}

#react-select-4-input {
    border: 0 !important;
}

#react-select-5-input {
    border: 0 !important;
}

#react-select-6-input {
    border: 0 !important;
}

#react-select-7-input {
    border: 0 !important;
}

#react-select-8-input {
    border: 0 !important;
}

.catSubCatSearchBox>.css-1nxu04f-control,
.catSubCatSearchBox>.css-2mqrqd-control {
    border-radius: 10px !important;
}

.csdqwu {
    display: var(--display);
}

.forcedcss {
    display: none !important;
}

.css-lkh0o5-menu {
    display: var(--display) !important;
}

.css-1hb7zxy-IndicatorsContainer {
    /* display: none !important; */
}

.icononlobby {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    z-index: 100;
    right: 20%;
    color: white;
    height: 24px;
    width: 24px;
}


.sabaricardforall {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.aspectRation {
    width: 100%;
}

.css-lkh0o5-menu {
    z-index: 101 !important;
}