.skillItem {
    width: max-content;
    display: inline-block;
    margin: 5px;
    background-color: var(--blue);
    padding: 5px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 50px;
    color: white;

}
.mailbake{
    word-break: break-all;
}

#bBtn{
    background-color: rgba(172, 255, 47, 0);
    border: none;
}
.det{
    padding-left: 10px;
}
@media only screen and (max-width: 511px) {

    .det{
        font-size: 10px;
    }

}

@media only screen and (max-width: 399px) {

    .det{
        font-size: 10px;
        display: block;
        padding: 0px;
    }

}


.fh1{
    font-weight: 700;
    font-size: 20px;
}

.Kbrn{
    text-decoration: none;
    color: black;
}

.vi{
    background-color: rgb(167, 11, 148);
    outline: none;
    border: none;
}
.vi:hover{
    background-color: rgb(167, 11, 148);
}


.qeuifgqevojhowiqhvipqenkvh{
    border-radius: 50px;
    background: var(--blue);
    color: white;

}

.srpojgpishrgps{
    max-width: 900px;
    padding-top: 80px;
}