@font-face {
  font-family: MAIAN;
  src: url(../../MAIAN.TTF);
}


.subscrption_font{
height: 100vh;
  padding-top: 100px;
  color: white;
  font-family:MAIAN ;
}

.ekhvorhv{
position: fixed;
right: 10px;

}